<template>
  <div class="tilt-angle-issues">
    <el-form
      class="analysis-page__form-box"
      ref="form"
      :model="formData"
      inline
      label-position="left"
    >
      <!-- <el-form-item label="Search" prop="search">
        <el-input v-model="formData.search" placeholder="e.g, DF12345" @blur="getData">
        </el-input>
      </el-form-item> -->

      <el-form-item label="Time and Date" prop="beginTime">
        <SelectDate type="datetime" v-model="formData.beginTime" @change="getData"></SelectDate>
      </el-form-item>
    </el-form>

    <div class="content-list">
      <div class="list-item">
        <div class="list-item__label _font-size__sub__heading _font-weight__600">
          Tilt Angle Issues
        </div>
        <!-- 
          width: calc(var(--main-width) - 490px - 40px - 50px)
          这里需要使用计算，不能使用 100% ，因为 100% 会有个计算过程需要等待父级计算结束，在进行计算
          100vw 则在页面渲染立即计算不需要等待父级计算。
          490px 为左侧元素宽度 50px 为左右元素之间间距，40px 右侧元素左右内边距和
          -->
        <PowerFulTable
          ref="PFTable"
          v-loading="loading"
          :data="sensorResult.rows"
          :columns="columns"
          :options="options"
          :surplusHeight="48"
          @sortCustom="handleSortCustom"
        />

        <el-pagination
          v-if="Number(sensorResult.total) !== 0"
          background
          layout="->, prev, pager, next"
          :total="Number(sensorResult.total)"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import PowerFulTable from '@/components/table/powerful-table'
import SelectDate from '@/components/select-datetime/select-date'
import common from '@/common'
import { parseTime } from '@/utils'
import { dataFindPage } from '@/api/data'
import { tiltHeaders } from '../index-data'

export default {
  components: {
    PowerFulTable,
    SelectDate
  },
  props: {
    code: {
      type: String,
      // default: common.code
      default: ''
    }
  },
  data() {
    return {
      // 表单区域数据
      formData: {
        beginTime: '',
        endTime: '',
        orderBy: '',
        sort: '',
        pageNo: 1,
        pageSize: 10
      },
      loading: true,
      // 列表数据
      options: {
        property: { highlightCurrentRow: true }
      },
      sensorResult: {
        rows: [],
        total: 0
      },
      columns: tiltHeaders
    }
  },
  activated() {
    this.$nextTick(() => {
      // this.$refs.PFTable.$refs.multipleTable.doLayout()
    })
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      dataFindPage({
        ...this.formData,
        endTime: this.formData.beginTime
          ? `${parseTime(this.formData.beginTime, '{y}-{m}-{d}')} 23:59:59`
          : '',
        towerCode: this.code
      }).then((res) => {
        this.loading = false
        this.sensorResult = res.result
        this.$emit('total', res.result.total)
      })
    },
    handleSortCustom({ prop, order }) {
      this.formData.orderBy = prop
      this.formData.sort = order ? order.replace('ending', '') : ''
      this.getData()
    },
    handleCurrentChange(e) {
      this.formData.pageNo = e
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.content-list {
  width: calc(100%);

  .list-item {
    padding: 20px 20px 0 20px;
    background: var(--color-white);

    &__label {
      margin-bottom: 25px;
    }
  }
}
</style>
